@font-face {
    font-family: 'trivia_sans_regular';
    src: url('../font/triviasansregular-webfont.woff2') format('woff2'),
         url('../font/triviasansregular-webfont.woff') format('woff'),
         url('../font/triviasansregular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'trivia_sans_regular';
    src: url('../font/triviasansregular-webfont.woff2') format('woff2'),
         url('../font/triviasansregular-webfont.woff') format('woff'),
         url('../font/triviasansregular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'trivia_sans_regular';
    src: url('../font/triviasansregular-bold-webfont.woff2') format('woff2'),
         url('../font/triviasansregular-bold-webfont.woff') format('woff'),
         url('../font/triviasansregular-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'trivia_sans_regular';
    src: url('../font/triviasansregular-bold-webfont.woff2') format('woff2'),
         url('../font/triviasansregular-bold-webfont.woff') format('woff'),
         url('../font/triviasansregular-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'trivia_sans_regular';
    src: url('../font/triviasansregular-bolditalic-webfont.woff2') format('woff2'),
         url('../font/triviasansregular-bolditalic-webfont.woff') format('woff'),
         url('../font/triviasansregular-bolditalic-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;

}

@font-face {
    font-family: 'trivia_sans_regular';
    src: url('../font/triviasansregular-bolditalic-webfont.woff2') format('woff2'),
         url('../font/triviasansregular-bolditalic-webfont.woff') format('woff'),
         url('../font/triviasansregular-bolditalic-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;

}

@font-face {
    font-family: 'trivia_sans_regular';
    src: url('../font/triviasansregular-italic-webfont.woff2') format('woff2'),
         url('../font/triviasansregular-italic-webfont.woff') format('woff'),
         url('../font/triviasansregular-italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'trivia_sans_regular';
    src: url('../font/triviasansregular-italic-webfont.woff2') format('woff2'),
         url('../font/triviasansregular-italic-webfont.woff') format('woff'),
         url('../font/triviasansregular-italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;

}

$baseFont: 'trivia_sans_regular',Arial,sans-serif;
$baseFontSize: 20px;
$baseLineHeight: 1.5;
$baseFontWeight: 400;

$baseFontColor: #282525;
$headingColor: #282525;

$white: #fff;
$black: #282525;
$gray: #aaa9a9;
$bgGray: #f5f1ed;
$footerTextGray: #696767;
$darkGray: #282525;
$beige: #ebe4dc;
$darkBeige: #d1c7bc;
$blue: #5188ac;
$bgBlue: #edf3f7;
$borderBlue: #cedee8;
$red: #ff492e;

$fbColor: #4267b2;
$twitterColor: #00aced; 

$lg: 1399px;
$md: 1199px;
$sm: 991px;
$xs: 767px;
$xxs: 479px;

$hoverBoxShadow: 0px 10px 21px 0px rgba(32, 46, 23, 0.25);