img.x-form-trigger { display: inline !important; }
div.pimcore_tag_multihref { background-color: #fff; }
.ib script { display: none !important; }
table.x-btn { line-height: normal !important; margin: 0 !important; padding: 0 3px 0 3px !important; }
table.x-btn td { line-height: normal !important; margin: 0 !important; padding: 0 !important; }
table.x-btn tr { border: none !important; }
.x-menu-list-item { line-height: normal !important; }

/*========================= Editor =========================*/

.cke_panel_container { background: #fff !important; }

.cke_ltr { background: #fff !important; }
.cke_ltr ul li { background: none; padding: 0; }
.cke_panel_listItem p, .cke_panel_listItem h1, .cke_panel_listItem h2, .cke_panel_listItem h3, .cke_panel_listItem h4, .cke_panel_listItem h5, .cke_panel_listItem h6, .cke_panel_listItem pre { font-size: inherit; font-style: normal; font-family: sans-serif; line-height: 30px; color: #000 !important; margin: 0; padding: 0; text-shadow: none; }
.cke_panel_listItem a { color: #000 !important; }
.cke_panel_listItem a:hover { color: #000 !important; }

.pimcore_editable { line-height: normal !important; }
.pimcore_area_entry { margin: 0 !important; padding: 0 !important; }

.item-list .item .pimcore_tag_link_text { display: inline-block; max-width: 90%; margin-right: 30px; overflow-x: hidden; }
.item-list .pimcore_area_entry { vertical-align: top !important; }

.partners .pimcore_tag_areablock { width: 100%; }
.partners .pimcore_area_entry { display: inline-block; width: 45%; background-color: #fff; margin: 5px !important; padding: 5px !important; }

.infoportals {
	.pimcore_tag_areablock {
		width: 100%;
	}
	.pimcore_editable {
		.item, .item-group-heading {
			width: 100%;
		}
	}
}

/* Editmode */
.pimcore_editable .content { background: none; }
.editmode .content:before { display: none; }
.editmode .contacts .item { max-width: 400px; }