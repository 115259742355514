.tabs { position: relative; }

.tabs .tab-nav ul { margin: 0; padding: 0 0 1em 0; text-align: center; }
.tabs .tab-nav li { display: inline-block; list-style-type: none; padding: 0 0.25em; margin: 0; background: none; font-size: 0.8em; color: $darkBeige; font-weight: 700; }
.tabs .tab-nav li a { display: block; text-decoration: none; outline: 0; color: $black; padding: 0.3em 0.5em; border-top: 2px solid $black; border-bottom: 2px solid $black; position: relative; }
.tabs .tab-nav li a .icon { transform: translateY(0.1em); }
.tabs .tab-nav li a:hover { background: $blue; color: $white; border-color: $blue; }
.tabs .tab-nav li a.selected { background: $black; color: $white; border-color: $black; position: relative; }
.tabs .tab-nav li a.selected:after { content: ""; display: block; width: 0; height: 0; border-style: solid; border-width: 0.5em 0.5em 0 0.5em; border-color: $black transparent transparent transparent; position: absolute; bottom: -0.5em; left: 50%; transform: translateX(-50%); }

.tabs .tab-content .tab { width: 100%; visibility: hidden; }
.tabs .tab-content .tab:not(.tab-not-animated) { transition: max-height 0.7s ease-in; max-height: 0; overflow: hidden; }
.tabs .tab-content .tab.selected { visibility: visible; max-height: 800px; position: static !important; }

@media screen and (max-width: $sm) {
	.tabs .tab-nav ul { padding: 0 0 1.75em 0; }
}

@media screen and (max-width: $xs) {
	.tabs .tab-nav ul { padding: 0 0 1em 0; }
	.tabs .tab-nav ul li { margin: 0 0 0.75em 0; font-size: 0.95em; }
}