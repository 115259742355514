.slider ul, .slider li.panel { margin: 0; padding: 0; list-style: none; position: relative; display: block; }
.slider, .slider li.panel { background: none; overflow: hidden; }
.slider li.panel:not(.slide):not(:first-child) { display: none; }

.slider-nav { display: block; text-align: center; width: 100%; }
.slider-nav ol { margin: 0 0 0 -10px; }
.slider-nav li { margin: 0 0 0 10px; padding: 0; background: none; display: inline-block; font-size: 0; }
.slider-nav li:before { content: none; }
.slider-nav li a { width: 8px; height: 8px; display: block; background: #2f4e75; text-indent: -80000px; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; }
.slider-nav li.current a { background: #b8bdc5; }

.slider-nav .prev { position: absolute; left: -3em; top: 50%; display: inline-block; width: 2em; height: 2em; transform: translateY(-50%); }
.slider-nav .prev:after { content: ""; position: absolute; right: 0.75em; top: 50%; display: block; width: 1em; height: 1em; border-left: 0.2em solid $blue; border-bottom: 0.2em solid $blue; transform: rotate(45deg) translate3d(-50%,0,1px); transform-origin: 0% 0%; z-index: 10; }
.slider-nav .prev:hover { border-color: $blue; }
.slider-nav .next { position: absolute; right: -3em; top: 50%; display: inline-block; width: 2em; height: 2em; transform: translateY(-50%); }
.slider-nav .next:after { content: ""; position: absolute; left: 1em; top: 50%; display: block; width: 1em; height: 1em; border-right: 0.2em solid $blue; border-top: 0.2em solid $blue; transform: rotate(45deg) translate3d(-50%,0,1px); transform-origin: 0% 0%; z-index: 10; }
.slider-nav .next:hover { border-color: $blue; }

/* Selections slider */

.selection-slider-wrapper { border-left: 20px solid transparent; border-right: 20px solid transparent; overflow: hidden; padding-bottom: 1.15em; }
.selection-slider { position: relative; }
.selection-slider .selection { min-height: 23em; }
.selection-slider .slider, .selection-slider .slider li.panel { overflow: visible !important; }
.selection-slider .slider li.panel { margin-right: 1.5em !important; }
.selection-slider .slider li.panel:not(.panel-current) { opacity: 0.3; }
.selection-slider .slider-nav .prev { height: 100%; width: 100%; left: auto; right: 100%; margin-right: 1.25em; }
.selection-slider .slider-nav .next { height: 100%; width: 100%; right: auto; left: 100%; margin-left: 2.5em; }

/* Playlists slider */

.playlist-slider-wrapper { border-left: 20px solid transparent; border-right: 20px solid transparent; overflow: hidden; }
.playlist-slider { position: relative; margin-bottom: 1.5em; }
.playlist-slider .slider, .playlist-slider .slider li.panel { overflow: visible !important; }
.playlist-slider .slider li.panel { margin-right: 1.5em !important; }
.playlist-slider .slider li.panel:not(.panel-current) { opacity: 0.3; }
.playlist-slider .slider-nav .prev { height: 100%; width: 100%; left: auto; right: 100%; margin-right: 1em; }
.playlist-slider .slider-nav .next { height: 100%; width: 100%; right: auto; left: 100%; margin-left: 2.25em; }

@media screen and (max-width: $sm) {
	.selection-slider { overflow: hidden; }
	.selection-slider .slider-nav .prev { width: 4em; height: 4em; right: auto; left: 0; margin-right: 0; }
	.selection-slider .slider-nav .prev:after { right: auto; left: 2em; }
	.selection-slider .slider-nav .next { width: 4em; height: 4em; left: auto; right: 0; margin-left: 0; }
	.selection-slider .slider-nav .next:after { left: 2.5em; }

	.playlist-slider { overflow: hidden; }
	.playlist-slider .slider-nav .prev { width: 4em; height: 4em; right: auto; left: 0; margin-right: 0; }
	.playlist-slider .slider-nav .prev:after { right: auto; left: 2em; }
	.playlist-slider .slider-nav .next { width: 4em; height: 4em; left: auto; right: 0; margin-left: 0; }
	.playlist-slider .slider-nav .next:after { left: 2.5em; }

	.playlist-slider .playlist { padding: 2em 3.5em; }
}