.header { padding-top: 2.25em; width: 100%; text-align: center; background: $beige; position: relative; z-index: 8000; }
.header .logo { display: block; position: absolute; left: 1.5em; top: 1.5em; width: 8em; height: auto; z-index: 1500; }

/* Main navigation */

.main-nav { margin: 0; width: 100%; }
.main-nav ul { margin: 0; }
.main-nav ul li { margin: 0; background: none; position: relative; font-size: 0.8em; padding: 0 1em; }
.main-nav ul li a { color: $blue; text-transform: uppercase; position: relative; display: block; line-height: 2.5em; white-space: no-wrap; text-decoration: none; font-weight: 700; }
.main-nav ul li:not(:last-child):before { content: ""; display: block; width: 1em; height: 2px; background: $blue; position: absolute; right: -0.5em; top: 1.2em; }
.main-nav ul li a:hover, .main-nav ul li a:active { color: $baseFontColor; transition: all 0.1s ease-out 0s; }
.main-nav ul li a:after { content: ""; display: block; width: 100%; height: 2px; background: $red; position: absolute; left: 0; bottom: 0.25em; visiblity: hidden; transform: scaleX(0); transition: none; }
.main-nav ul li a:hover:after { visibility: visible; transform: scaleX(1); transition: all 0.1s ease-out 0s; }
.main-nav ul li.active:after { content: ""; display: block; width: 100%; height: 2px; background: $blue; position: absolute; left: 0; top: 1.2em; }
.main-nav ul li.active a:hover:after { display: none; }
.main-nav ul li.active a { color: $baseFontColor; }

.main-nav ul li a.dropdown-trig:before { content: ""; display: block; width: 0; height: 0; border-style: solid; border-width: 0 0.75em 0.75em 0.75em; border-color: transparent transparent $black transparent; position: absolute; left: 50%; top: 3em; transform: translateX(-50%); transition: all 0.05s ease-in; opacity: 0; visibility: hidden; }
.main-nav ul li a.dropdown-trig-active:before { top: 2.75em; opacity: 1; visibility: visible; transition: all 0.1s ease-in 0.1s; }

.menu-trigger { border: none; width: 2.75rem; height: 2.75rem; position: absolute; right: 0.7em; top: 0.7em; display: none; text-align: center; padding: 0.5em; z-index: 2100; }
.menu-trigger .icon-menu { fill: $blue; width: 1.75rem; height: 1.75rem; display: block; }

.switches { position: absolute; right: 1.25em; top: 2.25em; z-index: 1000; }
.switches li { margin: 0 5px 0 0; padding: 0; min-width: 50px; vertical-align: top; }
.switches a { font-size: 0.8em; height: 2em; font-weight: 700; background: $blue; color: $white; display: block; }
.switches a:hover { text-decoration: none; background: $red; }

.switches .lang-switch { position: relative; padding: 0.35em 1em 0.25em 0.5em; border-radius: 10px 0 0 0; }
.switches .lang-switch:after { content: ""; display: block; width: 0; height: 0; position: absolute; right: 6px; top: 50%; margin-top: -1px; border-style: solid; border-width: 5px 4px 0 4px; border-color: $white transparent transparent transparent; }
.switches .langs { max-height: 0; transition: max-height 0.2s ease-in; overflow: hidden; width: 100%; }
.switches .langs.langs-open { max-height: 500px; }
.switches .langs li { margin: 5px 0 0 0; padding: 0; }
.switches .langs .lang { display: block; background: $blue; color: $white; padding: 0.25em 0.5em; }
.switches .lang:hover { background: $red; color: $white; }

.switches .search-switch { padding: 0.25em 0.5em 0.25em 0.5em; border-radius: 0 0 10px 0; }
.switches .search-switch .icon { transform: translateY(0.15em); }

.switches .social-nets-switch { display: none; padding: 0.35em 0.5em 0.25em 0.5em; border-radius: 10px 0 0 0; }
.switches .social-nets { margin-right: 0.35em; display: flex; }
.switches .social-nets li { display: inline-block; min-width: 0; }
.switches .social-nets .social-net { padding: 0; width: 2em; height: 2em; padding: 0.1em; border-radius: 3px; }
.switches .social-nets .social-net .icon { vertical-align: middle; width: 1.25em; height: 1.25em; }

.search { margin: 3em 0 1em 0; }
.search .btn { white-space: nowrap; }

/* Dropdowns */

.dropdown-open .header .logo { opacity: 0; visibility: hidden; transition: none; }

.dropdown { position: absolute; left: 0; top: 5em; width: 100%; color: $white; text-align: left; display: none; }
.dropdown.dropdown-open { box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.25); }

.dropdown-content { background: $black; padding: 1.5em 0 1.5em 0; position: relative; z-index: 450; }
.dropdown-head { font-size: 1.5em; font-weight: 700; min-width: 10em; margin-bottom: 0.6em; display: inline-block; text-decoration: underline; }
.dropdown-head:hover, .dropdown-head:active { color: $white; }
.dropdown-head .icon-arrow-headline { width: 0.6em; height: 0.6em; margin: 0.5em 0 0 0.15em; }
.dropdown-head:hover .icon-arrow-headline { fill: $blue; }

.dropdown-nav { margin: 0 0 0.6em 0; padding: 0; display: block; }
.dropdown-nav li { list-style: none; padding: 0; position: relative; margin: 0 0 0.35em 0; display: block; line-height: 1.4; }
.dropdown-nav li a { font-size: 0.8em; display: block; position: relative; padding-left: 1.5em; color: $white; }
.dropdown-nav li a:before { content: ""; display: block; width: 1em; height: 2px; background: $blue; position: absolute; left: 0; top: 0.5em; }
.dropdown-nav li a:hover { color: $white; }
.dropdown-nav li a:hover:before { background: $red; }
.dropdown-nav li .dropdown-nav-head { font-size: 1em; font-weight: 700; padding-left: 0; color: $white; text-decoration: underline; }
.dropdown-nav li .dropdown-nav-head:before { display: none; }
.dropdown-nav li span.dropdown-nav-head { text-decoration: none; font-weight: 700; }.dropdown-nav li .icon-arrow-headline { width: 0.65em; height: 0.65em; margin: 0.35em 0 0 0.15em; }
.dropdown-nav li a:hover .icon-arrow-headline { fill: $red; }


@media screen and (max-width: 1570px) {
	.main-nav { padding-right: 5em; }
	.header .logo { width: 6.75em; left: 1em; top: 1em; }
}

@media screen and (max-width: $lg) {

	.switches .social-nets { display: block; margin-right: 0; max-height: 0; transition: max-height 0.2s ease-in; overflow: hidden; }
	.switches .social-nets-switch { display: block; }
	.switches .social-nets li { display: block; margin: 5px 0 0 0; min-width: 50px; }
	.switches .social-nets .social-net { width: 100%; border-radius: 0; }
	.switches .social-nets.social-nets-open { max-height: 500px; }

	.switches .lang-switch { border-radius: 0 0 0 0; }

	.main-nav { padding-right: 2em; }
}

@media screen and (max-width: $md) {
	.menu-trigger { display: block; }

	.header { height: auto; min-height: 4em; width: 100%; padding-top: 0; }

	.main-nav { background: $black; z-index: 2000; width: 100%; left: 100%; top: 0; padding: 1em 1em 1.25em 5.5em; position: absolute; transition: left 0.25s ease-in; margin: 0; }
	.main-nav ul { width: 100%; }
	.main-nav ul li { display: block; width: 100%; padding: 0; }
	.main-nav ul li:before { display: none !important; }
	.main-nav ul li:after { top: 2.1em !important; }
	.main-nav ul li a { line-height: 4; font-size: 1.1em; text-decoration: none; color: $white !important; border-bottom: 1px solid lighten($black, 15%); }
	.main-nav ul li a { display: block; }
	.main-nav ul li a:hover:after, .main-nav li a:active:after, .main-nav li a:focus:after { top: 100%; }
	.main-nav ul li.active:after { display: none; }


	.menu-open .menu-trigger { right: 100%; transform: translateX(4.25em); transition: right 0.25s ease-in; }
	.menu-open .main-nav { left: 0; box-shadow: 0px 9px 18px 0px rgba($black, 0.4); }
	.menu-open .icon-menu { fill: $white; }
	.menu-open .header .logo { opacity: 0; }

	.switches { top: 1.4em; right: 4.5em; }

	.search { margin: 7em 0 1em 0; padding: 0; }

	.form-search input.text { margin-bottom: 0.75em; }
	.form-search .btn { width: auto; font-size: 1.5em; display: block; margin-left: auto; margin-right: auto; }
}

@media screen and (max-width: $sm) {
	.header .logo { top: 1em; left: 1em; width: 5.5em; }
}

@media screen and (max-width: $xs) {
	.header .logo { top: 0.5em; left: 0.5em; }
}