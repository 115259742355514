a { color: inherit; text-decoration: none; }
a:hover, a:active { text-decoration: underline; color: $red; }

.head-link a:hover, .head-link a:active { text-decoration: none; }

/* Heading */

h1, .h1 { font-weight: 700; color: $headingColor; font-size: 3em; line-height: 1.1; margin: 0 0 0.6em 0; }
h2, .h2 { font-weight: 700; color: $headingColor; font-size: 2em; line-height: 1.25; margin: 0 0 0.75em 0; }
h3, .h3 { font-weight: 700; color: $headingColor; font-size: 1.5em; line-height: 1.3; margin: 0 0 0.5em 0; }
h4, .h4 { font-weight: 400; color: $headingColor; font-size: 1.5em; line-height: 1.3; margin: 0 0 0.75em 0; }
h5, .h5 { font-weight: 400; color: $headingColor; font-size: 1em; line-height: 1.5; margin: 0 0 1em 0; }
h6, .h6 { font-weight: 400; color: $headingColor; font-size: 1em; line-height: 1.5; margin: 0 0 1em 0; }

/* Paragraphs */

p { margin: 0 0 1em 0; }
address { font-style: normal; }
cite { font-style: normal; }

/* Lists */

ul, ol { list-style: none;  margin: 0 0 1em 0; }
ul li { margin: 0 0 0.25em 0; padding: 0 0 0 1em; }
ul.unstyled li { padding: 0; background: none; }

ol { counter-reset: counter; margin-left: 0; *margin-left: 54px; }
ol li { margin: 0 0 0.5em 0; padding: 0 0 0 1em; *list-style-type: decimal; counter-increment: counter; position: relative; }
ol li:before { color: $gray; content: counter(counter)'. '; position: absolute; left: 0; top: 0; }

ul.list-files { list-style: none; }
ul.list-files li { padding: 0; list-style-type: none; }
ul.list-files li:before { display: none !important; }
ul.list-files .icon { width: 1.75em; height: 1.75em; margin-right: 0.5em; fill: $blue; }
ul.list-files a { text-decoration: none; font-weight: 700; color: $blue; }
ul.list-files a:hover { color: $baseFontColor; }

dl { margin: 0 0 1em 0; }
dt { font-weight: bold; }


.text-small { font-size: 0.7em; }
.text-big { font-size: 1.3em; }

.text-light { font-weight: 200; }
.text-bold { font-weight: 600; }
strong { font-weight: 600; }

.text-uppercase { text-transform: uppercase; }
.text-nowrap { white-space: nowrap; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.color-gray { color: $gray !important; }
.color-blue { color: $blue !important; }

.f-l { float: left; }
.f-r { float: right; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid $gray; margin: 1em 0; padding: 0; }

sup,sub { height: 0; line-height: 1; vertical-align: baseline; _vertical-align: bottom; position: relative; }
sup { bottom: 1ex; }
sub { top: .5ex; }

.entry p, .entry ul, .entry ol { font-size: 0.9em; }
.entry h2 { margin-top: 1.25em; margin-bottom: 0.6em; font-size: 1.5em; }
.entry h3 { margin-top: 1.25em; margin-bottom: 0.6em; font-size: 1.2em; }
.entry h2:first-child { margin-top: 0; }
.entry h3:first-child { margin-top: 0; }
.entry ul li { position: relative; padding-left: 2.75em; }
.entry ul li:before { content: ''; position: absolute; left: 1.75em; top: 0.6em; width: 5px; height: 5px; display: block; border-radius: 50%; background: $blue; }
.entry ol li { padding-left: 2.75em; }
.entry ol li:before { color: $blue; left: 1.5em; }
.entry a:not(.btn) { text-decoration: underline; }
.entry a:not(.btn):hover { text-decoration: none; }

.topic-content .row:not(.row-first) .entry h2:first-child { margin-top: 0.75em; }
.topic-content .row:not(.row-first) .entry h3:first-child { margin-top: 0.75em; }

.perex { font-size: 1.2em; line-height: 1.5; font-weight: 700; }
.perex-big { font-size: 1.5em; line-height: 1.4; font-weight: 400; }