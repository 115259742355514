.modal-box { display: none; background: $white; }
.modal-content { padding: 40px 30px; }

/* Subject link modal */

.subject-modal { font-size: 0.9em; width: 600px; max-width: 90vw; }
.subject-modal p:last-child { margin-bottom: 0; }

/* Qtip */

a.hint { border-bottom: 2px dotted $red; cursor: help; text-decoration: none !important; }
a.hint:hover { text-decoration: none !important; }

.qtip-glossary { max-width: 725px; border: 2px solid $beige; background-color: $white; border-radius: 10px 0 10px 0; font-size: .9em; color: $black; line-height: 1.2em; }
.qtip .term-text { width: 450px; }
.qtip .term-topics { width: 250px; }