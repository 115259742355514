.content { padding-top: 1.5em; background: $beige; }
.content-bg-circle { flex: 1 0 auto; position: relative; padding-top: 3em; overflow: hidden; }
.content-bg-circle:before { content: ""; display: block; width: 2200px; min-width: 130vw; height: 200vh; background: $beige; border-radius: 50%; position: absolute; left: 50%; top: -112vh; z-index: 1; transform: translateX(-50%); }

/* Block */

.block { padding: 3em 8em; background: $bgGray; }
.block-content { position: relative; z-index: 5; }

.block.block-narrow { padding: 1.5em 8em; }

.block.block-alt { background: $white; }

.block.block-alpha { padding-top: 0; }
.block.block-omega { padding-bottom: 0; }

.block.block-first { padding-top: 2.25em; }

.block.block-head { background: $beige; padding-top: 0; padding-bottom: 0.75em; }
.block.block-head { h1, .h1 { font-size: 2.5em; margin-bottom: 0.5em; } }
.block.block-head-alt { h1, .h1 { margin-bottom: 0.6em; } }

.section-head { text-align: center; }

.breadcrumbs { border-top: 2px solid $darkBeige; padding-top: 0.5em; margin-bottom: 2em; }
.breadcrumbs ul { list-style-type: none; }
.breadcrumbs ul li { color: $blue; font-size: 0.8em; padding-left: 0; padding-right: 0.8em; position: relative; display: inline-block; }
.breadcrumbs ul li:before { content: "/"; position: absolute; right: 0; color: $darkBeige; }
.breadcrumbs ul li:last-of-type:before{ display: none; }

.fix-nav { width: 100%; }
.fix-nav.fixed { position: fixed; background: $beige; width: 100%; left: 0; top: 0; z-index: 10000; }

.box { background: $white; padding: 1.5em; margin-bottom: 1.5em; border-radius: 10px 0 10px 0; }

.btn-more-wrap { margin: 0.5em 0 1em 0; }

.side-box { border-radius: 10px 0 10px 0; background: $beige; margin-bottom: 1em; padding: 1.25em 1.25em 1.4em 1.25em; }
.side-box-title { font-size: 0.9em; text-transform: uppercase; color: $black; margin-bottom: 0.6em; }
.side-box-links { list-style-type: none; margin-bottom: 0; }
.side-box-links li { padding-left: 0; margin-bottom: 0.75em; }
.side-box-links li:last-child { margin-bottom: 0; }
.side-box-links li a { color: $blue; position: relative; padding-right: 2.25em; display: block; font-size: 0.8em; }
.side-box-links li a { &:hover, &:active { color: $red; } }
.side-box-links li a .icon { position: absolute; right: 0; top: 0.15em; width: 1.72em; height: 1.72em; }

.side-nav-box-wrap { padding-right: 1em; }
.side-nav-box { background: $blue; color: $white; border-radius: 10px 0 10px 0; padding: 1em; }
.side-nav { margin-bottom: 0; padding: 0; list-style-type: none; }
.side-nav li { padding: 0; margin: 0; }
.side-nav > li > a { font-size: 0.9em; display: block; width: 100%; font-weight: 700; text-decoration: none; color: $white; border-bottom: 2px solid rgba($white, 0.5); transition: all 0.1s ease-in; padding: 0.6em 0; }
.side-nav > li > a { &:hover, &:active { text-decoration: none; color: $white; border-color: rgba($red, 1); } }
.side-nav > li:last-child > a { border-bottom: none; }

.side-nav-2 { margin-bottom: 0; padding-left: 0.75em; }
.side-nav-2 > li > a { font-size: 0.9em; display: block; text-decoration: none; color: $white; border-bottom: 2px solid rgba($white, 0.5); transition: all 0.1s ease-in; padding: 0.6em 0; }
.side-nav-2 > li > a { &:hover, &:active { border-color: rgba($red, 1); } }

.side-nav-3 { margin-bottom: 0; padding-left: 0.75em; border-top: 1px solid rgba($white, 0.5); }
.side-nav-3 > li > a { font-size: 0.8em; display: block; text-decoration: none; color: $white; border-bottom: 2px solid rgba($white, 0.5); transition: all 0.1s ease-in; padding: 0.65em 0; }
.side-nav-3 > li > a { &:hover, &:active { border-color: rgba($red, 1); } }
.side-nav-3 > li.active > a { font-weight: 900; border-bottom: none; }

.side-nav-anchors { padding: 00 1em; margin-bottom: 0; margin-left: -0.75em; background: $black; margin-right: -1.75em; }
.side-nav-anchors > li > a { font-size: 0.8em; display: block; text-decoration: none; color: $white; border-bottom: 2px solid rgba($white, 0.5); transition: all 0.1s ease-in; padding: 0.65em 0; }
.side-nav-anchors > li:first-child { padding-top: 0.15em; }
.side-nav-anchors > li:last-child { padding-bottom: 0.15em; }
.side-nav-anchors > li:last-child > a { border-bottom: none; }
.side-nav-anchors > li > a { &:hover, &:active { border-color: rgba($red, 1); } }

.example-box { background: $bgBlue; border-radius: 10px 0 10px 0; padding: 1.35em 1.5em; margin-bottom: 1.25em; margin-top: 0.15em; }
.example-box-head { display: block; font-size: 0.9em; text-transform: uppercase; font-weight: 700; margin-bottom: 0.8em; padding-bottom: 0.5em; border-bottom: 2px solid $borderBlue; }
.example-box-text { font-size: 0.9em; }
.example-box-text { p:last-child, ul:last-child, ol:last-child { margin-bottom: 0; } }

/* Teaser */

.teaser { background: $white; }

/* Overlay Info */
.overlay-info { padding: 40px 50px 30px 50px; }

/* Content */

.item-list { margin: 0 0 0 -1.5em; display: flex; flex-direction: row; flex-wrap: wrap; }
.item-list.item-list-center { justify-content: center; }
.item-list-1 .item { width: 100% !important; }
.item-list-2 .item { width: 50%; }
.item-list-3 .item { width: 33.33333%; }
.item-list-4 .item { width: 25%; }

.item { display: flex; align-items: stretch; margin: 0 0 1.5em 0; padding-left: 1.5em; position: relative; }
.item-inner { position: relative; width: 100%; background: $white; color: $white; font-weight: 700; border-radius: 10px 0 10px 0; overflow: hidden; display: flex; flex-direction: column; justify-content: flex-end; text-decoration: none; transition: all 0.08s ease-out; z-index: 10; }
.item-inner.item-inner-top { justify-content: flex-start; }
.item-inner:after { content: ""; display: block; width: 100%; height: 100%; background: linear-gradient(to bottom, rgba($darkGray,0) 0%, rgba($darkGray,0) 30%, rgba($darkGray,0.5) 70%, rgba($darkGray,1) 99%); position: absolute; left: 0; top: 0; z-index: 18; }
.item-inner .item-bg { position: absolute; left: 50%; top: 0; transform: translateX(-50%); max-width: none; width: auto; min-width: 100%; min-height: 100%; z-index: 15; }
.item-inner .item-text { padding: 1.5em 2.5em; position: relative; z-index: 20; }
.item-inner .item-title { color: $white; text-decoration: none; margin-bottom: 0.4em; }
.item-desc { color: $gray; font-size: 0.6em; text-transform: uppercase; margin-bottom: 0; }
.item-inner a { text-decoration: none; }
.item-desc a:hover, .item-desc a:active { text-decoration: underline; color: $red; }
.item-link { display: block; }

.item-clickable { &:hover, &:active { color: $white; text-decoration: none; transform: translateY(-8px); box-shadow: $hoverBoxShadow; transition: all 0.05s ease-in; cursor: pointer; }}
.item-inner { &:hover .item-title, &:active .item-title { color: $red; text-decoration: underline; }}

.item-no-img .item-title { color: $baseFontColor; }
.item-no-img .item-inner { color: $baseFontColor; }
.item-no-img .item-inner:after { display: none; }

.artists .item-title { font-size: 1.5em; }
.artists .item-list-3 .item-inner { min-height: 16em; }
.artists .item-list-4 .item-inner { min-height: 11.5em; }
.artists .item .item-inner:after { background: linear-gradient(to bottom, rgba($black,0) 0%, rgba($black,0) 30%, rgba($black,0.4) 80%, rgba($black,0.7) 99%); }

.workshops .item-inner { justify-content: flex-start; }
.workshops .item-text { padding: 1.5em 2em; }
.workshops .item-title { font-size: 1.5em; }
.workshops .item-inner { min-height: 11.5em; }
.workshops .item-desc { text-transform: none; font-size: 0.8em; margin-top: 1em; font-weight: 400; }

.news .item { width: 25%; }
.news .item-inner:before { content: "+"; display: block; color: $white; position: absolute; right: 0.55em; top: 0.2em; transform: scale(0.9, 0.95); z-index: 20; }
.news .item:not(.item-double) .item-title { font-size: 0.9em; margin-bottom: 0.75em; }
.news .item:not(.item-double) .item-text { padding: 1em 1.25em; }
.news .item:not(.item-double) .item-inner:after { background: linear-gradient(to bottom, rgba($darkGray,0) 0%, rgba($darkGray,0) 10%, rgba($darkGray,0.5) 40%, rgba($darkGray,1) 99%); }
.news .item-inner { min-height: 12em; }
.news .item-double { width: 50%; }
.news .item-double .item-text { padding: 2em 2.5em; }
.news .item-double .item-inner { min-height: 25.5em; }
.news .item-double .item-inner:before { transform: scale(1.5, 1.6); right: 0.65em; }
.news .items-wrap { width: 50%; display: flex; flex-direction: row; flex-wrap: wrap; height: 50%; }
.news .items-wrap .item { width: 50%; }
.news .item-no-img .item-inner:before { color: $red; }

.terms:not(:last-child) { border-bottom: 2px solid $beige; margin-bottom: 1.2em; }
.terms-letter { font-size: 1.5em; font-weight: 700; line-height: 1.2; margin-bottom: 0.75em; }
.term { padding-bottom: 1.35em; display: flex; flex-wrap: wrap; }
.term:not(:last-child) { border-bottom: 2px solid $beige; margin-bottom: 1.25em; }
.term-title { font-size: 1.5em; font-weight: 400; width: 100%; margin-bottom: 0.3em; }
.term-text { width: 70%; font-size: 0.9em; padding-right: 1.75em; }
.term-text p, .term-text ul, .term-text ol { &:last-child { margin-bottom: 0; } }
.term-topics { width: 30%; padding-left: 1.75em; border-left: 2px solid $borderBlue; font-size: 0.9em; }
.term-topics-head { margin-bottom: 0.5em; }
.term-topics ul li { position: relative; }
.term-topics ul li:before { content: ""; display: block; width: 5px; height: 5px; background: $blue; border-radius: 50%; position: absolute; left: 0; top: 0.5em; }
.term-topics ul li a { color: $blue; text-decoration: underline; }
.term-topics ul li a { &:hover, &:active { text-decoration: none; } }


.tags { margin: 0; padding: 0; }
.tags li { display: inline-block; font-size: 0.8em; margin: 0.15em 0.5em 0.15em 0; padding: 0; }
.tags li:last-child { margin-right: 0; }
.tags li a { display: inline-block; text-decoration: none !important; background: rgba($white, 0.5); color: rgba($white, 0.7); padding: 0.5em 0.75em; line-height: 1.1; font-weight: 700; border-radius: 10px 0 10px 0; }
.tags li a:hover { background: $red; color: $white; }

.tags-alt li a, .item-no-img .tags li a { background: $beige; color: $darkBeige; }
.tags-alt-2 li a { background: $white; color: $darkBeige; }
.tags-alt-2 li a.selected { background: $black; color: $gray; }

.tags-letters { margin-left: -0.5em; }
.tags-letters li { margin: 0.15em 0 0.55em 0.35em; }
.tags-letters li a { width: 2em; padding: 0.5em 0.15em; text-align: center; }

.box-download { border-radius: 10px 0 10px 0; background: $black; color: $white; display: block; overflow: hidden; }
.box-download-text { padding: 1em 3.75em 1em 1.25em; position: relative; }
.box-download-text .icon { position: absolute; right: 1.15em; top: 1.25em; fill: $red; width: 1.6em; height: 1.6em; }
.box-download-title { font-weight: 700; font-size: 1.2em; }
.box-download:hover, .box-download:active { color: $red; transform: translateY(-8px); box-shadow: $hoverBoxShadow; transition: all 0.05s ease-in; }

/* About / contact */

.contacts .item-inner { border: 2px solid $beige; padding: 1.25em 0.8em 1.25em 0.8em; text-align: center; color: $baseFontColor; }
.contacts .item-inner-center { display: flex; flex-direction: column; justify-content: center; }
.contacts .item-inner:after { display: none; }
.contacts .contact-img { border-radius: 50%; overflow: hidden; width: 8em; height: 8em; position: relative; margin: 0 auto; margin-bottom: 1em; }
.contacts .contact-img img { position: absolute; width: auto; max-width: none; min-width: 100%; min-height: 100%; left: 50%; top: 0; transform: translateX(-50%); }
.contacts p:last-child { margin-bottom: 0; }
.contacts a:not(.btn):hover { text-decoration: underline; }
.contacts .contact-name { font-size: 1.2em; margin-bottom: 0.4em; }
.contacts .contact-job { font-size: 0.6em; color: $gray; text-transform: uppercase; font-weight: 700; }
.contacts .contact-info { font-weight: 400; font-size: 0.8em; margin-bottom: 0.5em; color: $blue; }
.contacts .contact-desc { font-weight: 400; font-size: 0.8em; }
.contacts .contact-desc a { color: $blue; }
.contacts .item-no-img .contact-text { border-top: 1px solid $beige, 5%; border-radius: 10px 0 10px 0; }

.contact-box-wrapper { pointer-events: none; }
.contact-box { background: $blue; border-radius: 10px 0 10px 0; color: $white; font-weight: 700; padding: 2em; pointer-events: auto; }
.contact-box hr { height: 2px; border-top: 2px solid $black; margin: 0.8em 0; }
.contact-box p, .contact-box ul { margin-bottom: 0.8em; }
.contact-box  a { text-decoration: none; }
.contact-box a:hover { color: $white; text-decoration: underline; }
.contact-head { color: $black; font-size: 3em; line-height: 1; margin-bottom: 0.35em; }

/* Infoportals */

.infoportals .item { width: 100%; &.item-no-img { .item-content { width: 100%; } } }
.infoportals .item .item-group-heading { margin: 0; }
.infoportals .item-inner { display: flex; flex-direction: row; justify-content: space-between; border: 2px solid $beige; color: $black; }
.infoportals .item-inner:after { display: none; }
.infoportals .item-content { width: 80%; padding: 1.2em 1.5em 1.2em 1.5em; }
.infoportals .item-img { width: 20%; padding: 1em; display: flex; justify-content: right; align-items: center; }
.infoportals .item-title { font-size: 1.2em; color: $black; text-decoration: none; }
.infoportals .item-clickable { &:hover, &:active { .item-title { color: $red; text-decoration: underline; } }}
.infoportals .item-text { font-size: 0.9em; padding: 0; font-weight: 400; }
.infoportals .item .btn { font-size: 0.9em; width: 100%; text-align: center; }

/* About project */

.collaborator { display: flex; margin-bottom: 0.5em; border: 2px solid $beige; border-radius: 10px 0 10px 0; padding: 1.25em; }
.collaborator .collaborator-content { width: 80%; }
.collaborator .item-img { width: 20%; display: flex; justify-content: center; align-items: center; padding: 1em; }
.collaborator-title { color: $black; font-size: 1.2em; margin-bottom: 0.5em; }
.collaborator-text { color: $black; font-size: 0.9em; font-weight: 400; }
.collaborator-text { p, ul, ol { &:last-child { margin-bottom: 0; } } }

/* News */

.article-image { margin-bottom: 2.25em; border-radius: 10px 0 10px 0; overflow: hidden; max-height: 30em; }
.article-image img { width: 100%; }
.article-image.article-image-small { max-height: none; }
.article-gallery { margin-top: 1.75em; margin-bottom: 0.75em; }
.article .row:last-child .article-gallery { margin-bottom: 0; }

.article-share-title { font-size: 0.7em; color: $gray; text-transform: uppercase; font-weight: 700; margin-bottom: 0.5em; }
.article-share li { padding: 0; margin-bottom: 0.5em; }
.article-share li a { display: inline-block; color: $blue; }
.article-share li a:hover { color: $baseFontColor; }
.article-share .icon { width: 1.25em; height: 1.25em;  }

/* Listen */

.filter-category-count { color: $gray; }

.filter-values { padding: 2.75em 0 1.25em 0; color: $darkBeige; text-align: center; font-size: 0.8em; }
.filter-values p, .filter-values ul { vertical-align: middle; }
.filter-values-count { font-weight: 700; margin-right: 1em; }
.filter-selected li { padding: 0 0.5em; vertical-align: middle; }
.filter-selected li a { font-weight: 700; }
.filter-uncheck { transform: translateY(0.13em); display: inline-block; }

/* Artist detail */

.artist-intro { background: $black; color: $white; border-radius: 10px 0 10px 0; overflow: hidden; margin-bottom: 1.75em; position: relative; display: flex; align-items: stretch; flex-wrap: wrap; }
.artist-intro:after { content: ""; display: block; width: 100%; height: 100%; background: linear-gradient(to bottom, rgba(#000,0) 0%, rgba(#000,0) 20%, rgba(#000,0.5) 70%, rgba(#000,0.8) 99%); position: absolute; left: 0; top: 0; z-index: 18; }
.artist-intro-text { width: 60%; padding: 3em; min-height: 20em; position: relative; z-index: 20; }
.artist-intro-img { width: 40%; overflow: hidden; position: relative; }
.artist-intro-img img { max-width: none; min-width: 100%; width: auto; min-height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }

.artist-name { color: $white; font-size: 3em; line-height: 1.2; margin-bottom: 0.5em; }
.artist-prev, .artist-next { color: $blue; display: inline-block; margin-right: 0.5em; }
.artist-prev:hover, .artist-next:hover { color: $white; }
.artist-prev .icon, .artist-next .icon { width: 1.5em; height: 1.5em; }

.detail { font-size: 0.8em; margin-bottom: 1.25em; }
.detail:last-child { margin-bottom: 0; }
.detail-title { color: $blue; text-transform: uppercase; margin-bottom: 0.3em; }
.detail .tags { margin-bottom: 1em; font-size: 1.25em; }
.detail li { padding: 0; }
.detail a:not(.btn) { text-decoration: underline; }
.detail a:hover, .detail a:active { text-decoration: none; }
.detail-socialnets .icon { width: 1.3em; height: 1.3em; margin-right: 0.75em; }
.detail p:last-child { margin-bottom: 0; }

.artist-text { padding-left: 3em; }

.gallery .item { width: 33.33333%; height: 12em; overflow: hidden; position: relative; margin-bottom: 1.5em; }
.gallery .item a { position: relative; display: block; width: 100%;	height: 100%; overflow: hidden; }
.gallery .item img { max-width: none; width: auto; min-width: 100%; min-height: 100%; position: absolute; left: 50%; top: 0; transform: translateX(-50%); }
.gallery .item-double { width: 66.66666%; height: 25.5em; }
.gallery .items-wrap { width: 33.33333%; position: relative; display: flex; flex-direction: row; flex-wrap: wrap; }
.gallery .items-wrap .item { width: 100%; }
.gallery .icon-play { position: absolute; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); fill: $white; z-index: 50; width: 2.5em; height: 2.5em; transition: fill 0.15s ease-in; }
.gallery a:hover .icon-play { fill: $blue; }

.gallery.gallery-workshop .item { width: 50%; }
.gallery.gallery-workshop .item-double { width: 100%; }

.lector { position: relative; min-height: 5em; margin-bottom: 1.5em; }
.lector.lector-image { padding-left: 6.5em; }
.lector-img { position: absolute; left: 0; top: 0; width: 5em; height: 5em; border-radius: 50%; overflow: hidden; }
.lector-img img { max-width: none; width: auto; min-width: 100%; min-height: 100%; }

/* Banners */

.banner img { width: 100%; }
.banner.banner-wide { background-color: $bgGray; }

/* Partners */

.partners .item { width: 33.333333%; display: block; }
.partners a { margin-bottom: 10px; font-size: 1.2em; font-weight: 700; &:hover, &:active { background-color: $red; color: $white; text-decoration: none; } }

/* Topics */

.topics .item { width: 33.33333%; }
.topics .item-inner { padding: 1em 1.5em; text-decoration: none; color: $black; font-weight: 400; }
.topics .item-title { color: $blue; font-size: 1.2em; }
.topics .item-title .icon { color: $red; width: 1em; height: 1em; margin-top: 0.15em; }
.topics .item-inner:hover, .topics .item-inner:active { background: $red; color: $white; }
.topics .item-inner:hover, .topics .item-inner:active { .item-title { color: $white; text-decoration: none; } }
.topics .item-inner:hover, .topics .item-inner:active { .icon { fill: $white; } }
.topics .item-inner:after { display: none; }

.topic-content { padding-top: 2.25em; }

/* Gmap */

.gmap { width: 100%; height: 100%; position: absolute; left: 0; top: 0; }
.gmap img { max-width: inherit; }

.popup-contact { background: $white; color: $black; width: 190px; height: 80px; padding: 0.75em; }
.popup-contact ul { margin: 0; }
.popup-contact ul li { padding: 0; background: none; }

/* Pager */

.pager { margin: 0.75em 0 1em 0; padding-top: 0.25em; text-align: center; position: relative; font-weight: 700; }
.pager ul { margin-bottom: 0; }
.pager li { display: inline-block; background: none; position: relative; padding: 0 0.4em; font-size: 1.5em; color: $blue; }
.pager li:after { content: ""; display: block; width: 0.8em; height: 4px; background: $blue; position: absolute; right: -0.5em; top: 0.75em; }
.pager li.pager-first, .pager li.pager-last { padding: 0; &:after { display: none; } }
.pager li.pager-list-first { padding-left: 0; }
.pager li.pager-list-last { padding-right: 0; &:after { display: none; } }
.pager li a { display: inline-block; line-height: 1.7; text-align: center; padding: 0 0.2em; }
.pager li a:hover, .pager li a:active { text-decoration: none; color: $baseFontColor; }
.pager li .pager-active { color: $baseFontColor; text-decoration: none; }
.pager .pager-prev { position: absolute; left: 0; top: 0; }
.pager .pager-next { position: absolute; right: 0; top: 0; }
.pager .pager-counter { display: none; }

@media screen and (max-width: $lg) {
	.contacts .item { width: 33.33333%; }
}

@media screen and (max-width: $md) {
}

@media screen and (max-width: $sm) {
	.content { padding-top: 4em; }
	.block { padding-left: 0 !important; padding-right: 0 !important; }

	.article-share li { display: inline-block; margin-right: 0.75em; }
	.article-share .icon { width: 1.5em; height: 1.5em; }

	.detail-socialnets .icon { width: 1.5em; height: 1.5em; }

	.artist-text { padding-left: 0; }

	.topics .item { width: 50%; }

	.news .item { width: 50%; }
	.news .item-double { width: 40%; }
	.news .items-wrap { width: 60%; }
	.news .items-wrap .item { width: 50%; }
	.news .items-wrap .item .item-title { font-size: 1em; }

	.contacts .item { width: 50%; }

	.side-nav-box-wrap { margin-top: 1.55em !important; }
}

@media screen and (max-width: $xs) {
	.item-list-4 .item { width: 50%; }
	.news .item { width: 50%; }
	.news .item-double { width: 100%; }
	.news .items-wrap { width: 100%; }
	.news .items-wrap .item { width: 50%; }

	.item-list-3 .item { width: 50%; }

	.gallery .item { width: 50%; }
	.gallery .item-double { width: 50%; height: 12em; }
	.gallery .items-wrap { width: 100%; }
	.gallery .items-wrap .item { width: 50%; }

	.pager .pager-prev { position: relative; margin: 0.5em 0.5em 0 0.5em; min-width: 6em; }
	.pager .pager-next { position: relative; margin: 0.5em 0.5em 0 0.5em; min-width: 6em; }

	.playlist-header .tags { display: block; float: none; }

	.artist-intro-text { width: 100%; min-height: 0; padding: 2em 2.25em 2.25em 2.25em; }
	.artist-name { font-size: 2.5em; }
	.artist-intro-img { width: 100%; height: 16em; }

	.detail { margin-bottom: 1.75em; }

	.filter-values { font-size: 0.95em; }

	.contact-box { margin-bottom: 300px; }

	.map { height: 15em; }

	.subject-head { padding: 7.75em 1.5em 1.5em 1.5em; padding-right: 0; }
	.subject-name { width: 100%; margin-bottom: 1em; }

	.subject-text { width: 60%; }
	.subject-details { width: 40%; }

	.topics .item { width: 100%; }

	.box-download { max-width: 360px; }

	.infoportals .item-inner { flex-direction: column; }
	.infoportals .item-inner { .item-content, .item-img { width: 100%; } .item-img { justify-content: center; } }

	.collaborator { flex-direction: column; }
	.collaborator .collaborator-content { width: 100%; }
	.collaborator .item-img { width: 100%; display: flex; justify-content: center; align-items: center; padding: 1.5em 1em 0 1em; }
}

@media screen and (max-width: $xxs) {
	.item-list .item { width: 100% !important; }

	.news .item:not(.item-double) .item-title { font-size: 1.1em; }

	.pager .pager-prev { font-size: 1.3em; margin: 0.5em 0.25em 0 0.25em; }
	.pager .pager-next { font-size: 1.3em; margin: 0.5em 0.25em 0 0.25em; }

	.map { height: 13em; }

	.subject-text { width: 100%; }
	.subject-details { width: 100%; }

	.term .term-text { width: 100%; }
	.term .term-topics { width: 100%; border-left: none; border-top: 2px solid $borderBlue; padding-top: 1em; margin-top: 1.25em; }
}