.btn { font-size: 1.5rem; line-height: 1.2; display: inline-block; vertical-align: middle; border: none; border-top: 2px solid $blue; border-bottom: 2px solid $blue; background: transparent; color: $blue; font-weight: 700; padding: 0.35em 0.35em; text-decoration: none; transition: transform 0.1s ease-in, box-shadow 0.1s ease-in, background-color 0.05s ease-in; }
.btn:hover { background: $red; color: $white; border-color: $red; text-decoration: none; transform: translateY(-6px); box-shadow: $hoverBoxShadow; }
.btn:active { background: $red; color: $white; border-color: $red; }

.btn-alt { border-color: $blue; color: $blue; }

.btn.btn-static:hover { transform: none; }

.btn-small { font-size: 1rem; }

.btn .icon-arrow { width: 0.8em; height: 0.8em; margin-top: 0.2em; }

.btn.btn-search:before { content: ""; display: inline-block; width: 0.9em; height: 0.9em; background: url('../svg/src/icon-search.svg') center center no-repeat; background-size: 100% 100%; transform: translate(-0.25em, 0.15em); }
.btn.btn-search:hover:before { background-image: url('../svg/src/icon-search-hover.svg'); }