form.std label { font-family: $baseFont; font-size: 0.8em; color: $black; border: none; margin: 0 0 1em 0; display: block; }
form.std input.text { font-family: $baseFont; font-size: 0.9em; color: $black; border: none; width: 100%; background: $white; }
form.std textarea { font-family: $baseFont; font-size: 0.9em; color: $black; resize: vertical; border: none; width: 100%; min-width: 100%; max-width: 100%; height: 150px; min-height: 150px; max-height: 300px; background: $white; padding: 0.5em 1em; }
form.std select { font-family: $baseFont; font-size: 0.9em; color: $black; width: 100%; padding: 2px 10px; }
form.std option { font-family: $baseFont; font-size: 0.9em; padding: 2px 20px; }

form.std input.text[required="required"] { background-image: url('../img/req.png'); background-position: right center; background-repeat: no-repeat; }

/*================================= Form standard =================================*/

/* Antispam input */
#lastName1 { display: none; }

/* Newsletter */

.newsletter { color: $white; }
.newsletter input.text { background: #535151; font-size: 0.9em; color: lighten($footerTextGray, 40%); padding: 1.15em 1em; border: none; width: 100%; }
.newsletter input.text:focus { background: $white; color: $baseFontColor; }
.newsletter p { font-size: 0.85em; }
.newsletter-title { color: $white; margin-bottom: 0.1em; }
.newsletter .btn { line-height: 1.4; padding: 0.25em 0.35em 0.35em 0.35em; width: 100%; }
.newsletter-text { width: 40%; }
.newsletter-input { width: 34%; padding: 0 30px; }
.newsletter-btn { width: 26%; }

/* Search */

.form-search input.text { background: $white; color: $baseFontColor; font-family: $baseFont; font-size: 1.5em; padding: 0.45em 0.75em; width: 100%; border: none; }
.form-search .btn { padding: 0.3em 0.35em 0.4em 0.35em; width: 100%; }

/* Contact */

.form-contact-wrap { background: $beige; border-radius: 10px 0 10px 0; padding: 1.5em 1.75em; }
.form-contact-wrap label { text-transform: uppercase; font-weight: 400; opacity: 0.5; }
.form-contact-wrap .btn { padding: 0.3em 0.35em 0.4em 0.35em; width: 10em; max-width: 100%; }
.form-contact-wrap input.text { padding: 0.7em 0.7em; }
.form-contact-wrap textarea { padding: 0.7em 0.7em; }

/* Standard */

form.std .cell { margin: 0 0 1.25em 0; }

/* Inline */

form.std .set-2 .cell { width: 50%; }
form.std .set-3 .cell { width: 33.3333%; }
form.std .set-4 .cell { width: 25%; }
form.std .set-5 .cell { width: 20%; }

form.std .toggle { position: relative; padding: 0 0 0 25px; }
form.std .toggle label { display: inline-block; *display: inline; zoom: 1; vertical-align: top; margin: 0 0 0 0; }
form.std .toggle .note { font-size: 16px; max-width: 100%; }

/* Various */

form.std .radio,
form.std .checker { position: absolute; left: 0; top: 0; }


@media screen and (max-width: $md) {
	.newsletter-text { width: 38%; }
	.newsletter-btn { width: 28%; }
	.newsletter-btn .btn { font-size: 1.25em;  padding: 0.45em 0.35em 0.55em 0.35em; }
}

@media screen and (max-width: $sm) {
	.newsletter-text { width: 100%; }
	.newsletter-input { width: 60%; padding-left: 0; }
	.newsletter-btn { width: 40%; }
}

@media screen and (max-width: $xs) {
	.newsletter-input { width: 100%; padding-right: 0; }
	.newsletter-btn { width: 100%; margin-top: 1em; text-align: left; }
	.newsletter-btn .btn { width: auto; }
}

@media screen and (max-width: $xxs) {
	
}