/* SVG icons */
svg { pointer-events: none; }
.icon { width: 1.25em; height: 1.25em; display: inline-block; *display: inline; zoom: 1; vertical-align: top; position: relative; fill: currentColor; }
.icon-porta { width: 65px; }

.icon-external-link { width: 0.8em; height: 0.8em; transform: translateY(0.4em); margin-left: 0.5em; }

h2 .icon-arrow-headline, .h2 .icon-arrow-headline { width: 0.6em; height: 0.6em; margin-top: 0.35em; }

.detail-socialnets .icon-spotify { height: 1.4em; }
.detail-socialnets .icon-musicjet { width: 1.4em; height: 1.4em; }
.detail-socialnets .icon-bandcamp { width: 1.6em; height: 1.6em; }
.detail-socialnets .icon-soundcloud { width: 1.5em; height: 1.5em; }