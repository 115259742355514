html { height: 100%; font-size: $baseFontSize; overflow-x: hidden; position: relative; }

body {
	 font-family: $baseFont;
	 font-size: $baseFontSize;
	 line-height: $baseLineHeight;
	 color: $baseFontColor;
	 background: $white;
	 font-weight: $baseFontWeight;
	 margin: 0;
	 padding: 0;
	 min-height: 100%;
	 overflow: hidden;

	 display: flex;
	 flex-direction: column;
}

/* Box sizing */

*, *:after, *:before { box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }

/* Forms */

form { display: block; }
fieldset { border: none; }
label { font-weight: bold; }
input, select, button { vertical-align: top; }
a, a:hover, a:active, a:focus { outline: 0; }

/* Various */

img { max-width: 100%; height: auto; }

.last { margin-bottom: 0 !important; }

.sh-content { display: none; }

/* Alignment */

.v-top { vertical-align: top; }
.v-middle { vertical-align: middle; }
.v-bottom { vertical-align: bottom; }

/* Vertical align */

.va-wrap { display: table; width: 100%; height: 100%; }
.va-m { display: table-cell; vertical-align: middle; height: 100%; }
.va-t { display: table-cell; vertical-align: top; height: 100%; }
.va-b { display: table-cell; vertical-align: bottom; height: 100%; }

.col-set {  display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-wrap: wrap; width: 100%; }

.col2-set .col { width: 49%; margin-bottom: 1em; }
.col3-set .col { width: 32%; margin-bottom: 1em; }
.col4-set .col { width: 24%; padding: 0 0 0 1em; }

.ib { letter-spacing: -0.31em; *letter-spacing: normal; *word-spacing: -0.43em; }
.ib > * { display: inline-block; *display: inline; zoom: 1; vertical-align: top; letter-spacing: normal; word-spacing: normal; }


/* Layout */

@for $i from 0 through 5 {
  .m-t-#{$i} { margin-top: #{$i}em !important; }
  .m-b-#{$i} { margin-bottom: #{$i}em !important; }
  .m-l-#{$i} { margin-left: #{$i}em !important; }
  .m-r-#{$i} { margin-right: #{$i}em !important; }
  .p-t-#{$i} { padding-top: #{$i}em !important; }
  .p-b-#{$i} { padding-bottom: #{$i}em !important; }
  .p-l-#{$i} { padding-left: #{$i}em !important; }
  .p-r-#{$i} { padding-right: #{$i}em !important; }

  .m-t--#{$i} { margin-top: -#{$i}em !important; }
  .m-b--#{$i} { margin-bottom: -#{$i}em !important; }
  .m-l--#{$i} { margin-left: -#{$i}em !important; }
  .m-r--#{$i} { margin-right: -#{$i}em !important; }
  .p-t--#{$i} { padding-top: -#{$i}em !important; }
  .p-b--#{$i} { padding-bottom: -#{$i}em !important; }
  .p-l--#{$i} { padding-left: -#{$i}em !important; }
  .p-r--#{$i} { padding-right: -#{$i}em !important; }
}

.m-b-0-25 { margin-bottom: 0.25em !important; }
.m-b-0-5 { margin-bottom: 0.5em !important; }
.m-b-0-75 { margin-bottom: 0.75em !important; }
.m-b-1-25 { margin-bottom: 1.25em !important; }
.m-b-1-5 { margin-bottom: 1.5em !important; }
.m-b-1-75 { margin-bottom: 1.75em !important; }
.m-b-2-5 { margin-bottom: 2.5em !important; }

.m-t-0-25 { margin-top: 0.25em !important }
.m-t-0-5 { margin-top: 0.5em !important; }
.m-t-0-75 { margin-top: 0.75em !important; }
.m-t-1-25 { margin-top: 1.25em !important; }
.m-t-1-5 { margin-top: 1.5em !important; }
.m-t-1-75 { margin-top: 1.75em !important; }
.m-t-2-5 { margin-top: 2.5em !important; }

.no-margin { margin: 0; }
.no-padding { padding: 0; }

.pos-relative { position: relative; }

@media screen and (max-width: $lg) {
	body { font-size: 0.95em; }
}

@media screen and (max-width: $md) {
	body { font-size: 0.9em; }
}

@media screen and (max-width: $sm) {
	body { font-size: 0.8em; }
}

@media screen and (max-width: $xs) {

}

@media screen and (max-width: $xxs) {

}